import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style1">
        <div className="inner">
            <header className="major">
                <h1>事業概要</h1>
            </header>
            <div className="content">
                <p>当法人の事業の概要についてご紹介します。
                <br />
                趣旨にご賛同いただける方の入会をお待ちしております。</p>
            </div>
        </div>
    </section>
)

export default BannerLanding

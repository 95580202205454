import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerGaiyou from '../components/BannerGaiyou'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Gaiyou = (props) => (
    <Layout>
        <Helmet>
            <title>事業概要</title>
            <meta name="description" content="機構の概要" />
        </Helmet>


        <BannerGaiyou
     />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>事業概要</h2>
                    </header>
                    <p>　都市構造可視化推進機構は，まちづくりの主体である行政や民間企業，地域住民等に対して，インターネット及びビジュアライゼーションツールを活用した都市の可視化に関する事業を行い，都市計画やまちづくりの検討に必要な都市構造の把握に寄与することを目的としています。
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/kashikakeikaku" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>都市の可視化に関するウェブサイトの運営事業</h3>
                            </header>
                            <p>都市構造可視化計画ウェブサイトの運営を行う日本都市計画学会都市構造評価特別委員会、福岡県、建築研究所と連携して、技術的なサポートを行っています。
                            </p>
                            <ul className="actions">
                                <li><Link to="/kashikakeikaku" className="button">都市構造可視化計画</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                
                <section>
                    <Link to="/gaiyou" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>都市の可視化に関する情報の取得事業等</h3>
                            </header>
                            <p>　都市の可視化に関するノウハウを活用しています。<br/>　会員の皆様はこれらの蓄積を活用した可視化の研究が可能です。<br/>
                            　※データの出典等に応じて諸条件があります。
                            </p>
                            <ul className="actions">
                                <li><Link to="https://mieruka.city/features/view/6" className="button">掲載データの例（都市構造可視化計画）</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section>
                    <Link to="/landing" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>情報システムの開発・運営事業等</h3>
                            </header>
                            <p>都市の可視化を活用したサービスの開発、運営等を行っています。</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button">Coming Soon!</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/kensyu" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>研修会やイベントの企画運営事業</h3>
                            </header>
                            <p>都市の可視化の促進のため、地方公共団体等行政機関、教育機関、民間事業者等における研修会の企画・運営支援事業やイベントの企画・運営事業を行っています。<br/>
                            </p>
                            <ul className="actions">
                                <li><Link to="/kensyu" className="button">研修支援のご案内</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section>
                    <Link to="/kentei" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>都市計画ビジュアルコミュニケーター検定等</h3>
                            </header>
                            <p>都市計画ビジュアルコミュニケーター検定の実施および関連資料の販売等を行っています。<br/>
                            その他，当法人の目的を達成するための事業を行っています。</p>
                            <ul className="actions">
                                <li><Link to="/kentei" className="button">検定のご案内</Link></li>
                            </ul>
                            <ul className="actions">
                                <li><a href="https://www.amazon.co.jp/s?i=stripbooks&rh=p_27%3A%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&s=relevancerank&text=%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&ref=dp_byline_sr_book_1" className="button">テキスト等はこちら</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/nyukaidetail" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>入会ご希望の方はこちらから</h3>
                            </header>
                            <p>地方公共団体等行政機関、教育機関、民間事業者等、多くの皆様の入会をお待ちしております。<br/>
                            </p>
                            <ul className="actions">
                                <li><Link to="/nyukaidetail" className="button">入会のご案内</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Gaiyou